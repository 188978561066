.form {
  width: 100%;

  &__submit {
    @include media-breakpoint-down(lg) {
      margin-right: auto;
    }
    &::after {
      border: 1px solid rgba($green, 0.8);
    }

    &::before {
      background-color: $green;
    }

    @include media-up(1025) {
      &:hover {
        span {
          color: $green;
        }

        &:after {
          opacity: 1;
          transform: scale(1, 1);
        }

        &:before {
          opacity: 0;
          transform: scale(0.5, 0.5);
        }
      }

      &:active {
        span {
          color: $white;
        }
      }
    }
  }

  .form-control {
    transition: all .3s ease;

    &:hover,
    &:focus {
      border-bottom: 1px solid $green;
    }
  }

  .form-group {
    position: relative;

    &.valid {
      .form-control {
        border-bottom: 1px solid $green;
      }

      .icon-done {
        opacity: 1;
        right: 16px;
      }
    }

    &.invalid {
      .form-control {
        border-bottom: 1px solid $red;
      }

      .invalid-feedback {
        top: calc(100% + 5px);
        opacity: 1;
      }

      .icon-close {
        opacity: 1;
        right: 16px;
      }

      .form-checkbox  {
        border: 1px solid $red;
      }
    }
    .form-checkbox {
      transition: all .2s ease;
    }

    .invalid-feedback {
      position: absolute;
      top: 95%;
      left: 0;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      color: $red;
      transition: all .3s ease;
      opacity: 0;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      fill: none;
      opacity: 0;
      transition: all .3s ease-in-out;

      &-done {
        width: 13px;
        height: 9px;
        stroke: $green;
      }

      &-close {
        width: 12px;
        height: 12px;
        stroke: $red;
      }
    }
  }
}
