.service {
  width: 100%;

  margin-bottom: 70px;
  @media (min-width: 1280px) {
    min-height: 800px;
  }

  &__img-overlay {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__gallery {
    width: 100%;
    overflow: hidden;
    @media (max-width:  991px) {
      display: none;
    }
    .swiper-wrapper {
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
  }
  &__gallery-item {
    width: 100%;
    height: 100%;
    position: relative;

    &.swiper-slide {
      opacity: 0 !important;
      transition: all 1000s ease;
    }

    &.swiper-slide-active {
      opacity: 1 !important;
    }
  }
  &__content {
    width: 100%;
    height: 100%;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__list {
    &.swiper-pagination {
      display: flex;
      position: absolute;
      width: 100%;
      left: 0;
      height: 100px;
      top: 0;
      z-index: 8;

      .swiper-pagination-bullet {
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: var(--white);
        opacity: 0.4;
        width: 33.3%;
        padding-left: 30px;
        position: relative;
        cursor: pointer;
        background: none;
        padding-top: 25px;
        font-size: 38px;
        @media (min-width: 1440px) {
          font-size: 40px;
        }
        &:first-child{
          text-align: left;
          width: 32.5%;
        }
        &:last-child{
          text-align: left;
          width: 32.5%;
        }
        &:nth-child(2){
          padding-left: 0;
          position: relative;
          left: -2px;
          padding-right: 5px;
        }
        &:after {
          display: none;
        }

        &:before {
          content: '';
          width: 1px;
          height: 850px;
          position: absolute;
          right: 0;
          top: 0;
          background: rgba(255, 255, 255, 0.4);
        }
        &:last-child {
          padding-left: 38px;
          &:before {
            display: none;
          }
        }

        &.swiper-pagination-bullet-active {
          color: var(--white);
          text-decoration: underline;
          font-weight: 400;
          opacity: 1;
        }
      }
    }
  }
  &-block {
    position: absolute;
    left: 0;
    width: 28%;
    max-width: 389px;
    bottom: 25px;
    padding: 17px 24px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    margin-left: 32px;

    @media (max-width: 991px) {
      width: 85%;
      max-width: 90%;
      top: 90px;
    }

    &__text {
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: var(--white);
      padding-bottom: 25px;

      @media (min-width: 992px) {
        font-size: 16px;
      }
      @media (min-width: 1280px) {
        font-size: 18px;
      }
    }
  }
}
.slide2 {
  .service-block {
    left: 33.5%;
  }
}
.slide3 {
  .service-block {
    left: 66.5%;
  }
}
.recommended {
  &__list {
    margin-bottom: 20px;
  }
  &__item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    padding-bottom: 2px;
    position: relative;
    &:before {
      content: '';
      width: 23px;
      height: 23px;
      background: url("../svg/Done.svg") 0 0 no-repeat;
      background-size: contain;
      display: inline-block;
      margin-right: 9px;
      position: relative;
      top: 5px;
    }
  }
  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.5;
    padding-bottom: 1px;
  }
}

.service-mobile {
  display: block;
  @media (min-width:  992px) {
    display: none;
  }
  .service-block {
    display: none;
  }
  &__item {
    width: 100%;
    min-height: 206px;
    position: relative;
    transition: all 0.3s ease-in;
    &__img {
      width: 100%;
      object-fit: cover;
      height: 206px;
    }
    &:target {
      min-height: 430px;
      .service-mobile__title {
        text-decoration: underline;
      }
      .service-block {
        display: block;
      }
      img {
        width: 100%;
        object-fit: cover;
        min-height: 430px;
      }
    }
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    position: absolute;
    top: 22px;
    left: 16px;
    color: var(--white);
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

  }
}
