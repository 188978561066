.single-service-offer {
  padding-bottom: 48px;
  position: relative;
  z-index: 10;
  &.bigger-padding {
    padding-bottom: 64px;
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 40px;
    &,
    &.bigger-padding {
      padding-bottom: 40px;
    }
  }

  &__table {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    background-color: $grey-100;
    overflow: hidden;

    &-title {
      margin-bottom: 32px;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 120%;
      text-align: center;
      color: $white;
    }

    &-header {
      display: flex;
      align-items: flex-start;
      background: $grey-200 url('../svg/services-table-header-bg.svg') 0 0 / contain no-repeat;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
        padding: 16px 24px 18px;
      }
      @include media-breakpoint-down(sm) {
        background: $grey-200 url('../svg/services-table-header-bg-mobile.svg') 0 0 / contain no-repeat;
      }

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $green;
      }

      &-left,
      &-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-block: 50px;
        @include media-breakpoint-down(md) {
          padding-block: 8px;
          text-align: center;
        }

        span {
          display: block;
          @include inherit;
        }
      }

      &-right {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: $white;

        span {
          max-width: 552px;
        }
      }

      &-left {
        max-width: 380px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
        color: $white;
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
      }
    }

    &-body {
      padding-block: 64px 92px;
      padding-inline: 24px;
      @include media-breakpoint-down(md) {
        padding-block: 32px 38px;
      }
    }
  }
}
