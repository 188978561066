.services-page {
  padding-top: var(--header-height);
  padding-bottom: 80px;
  background: $black url('../svg/services-banner.svg') 0 0 /contain no-repeat;
  background-size: 100%;
  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    background: $black url('../svg/services-banner-mobile.svg') 0 0 /contain no-repeat;
  }

  .container {
    max-width: 1160px;
  }

  &__banner {
    padding-top: 157px;
    @include media-breakpoint-down(lg) {
      padding-top: 50px;
    }
  }

  &__title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    margin-bottom: 32px;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: $white;
    @include media-breakpoint-up(lg) {
      font-weight: 400;
      font-size: 56px;
    }
  }

  &__subtitle {
    margin-bottom: 60px;
    max-width: 781px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: $white;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      margin-bottom: 44px;
      font-size: 16px;
    }
  }

  &__item {
    padding-block: 32px;
    @include media-breakpoint-down(lg) {
      padding-block: 16px;
    }
  }
}
