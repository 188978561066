.tabs {
  width: 100%;
  &__content {
    &-item {
      &:not(:first-of-type) {
        display: none;
      }
    }
  }
}
