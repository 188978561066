.not-found-page {
  background-color: var(--black);

  .footer {
    display: none;
  }

  .common-cover-section__title,
  .common-cover-section__text {
    text-align: start;
    color: var(--white);
    @include media-breakpoint-down(sm) {
      br {
        display: none;
      }
    }
  }

  .common-cover-section__title {
    @include media-breakpoint-down(sm) {
      font-size: 30px;
    }
  }

  .btn-green {
    padding: 26px 32px;
    @include media-breakpoint-down(sm) {
      padding: 20px 30px;
    }

    &:hover {
      @include media-up(1025) {
        border-color: var(--white);

        .icon-arrow-right {
          stroke: var(--white);
        }

        span {
          color: var(--white);
        }
      }
    }
  }

  &__banner {
    padding-block: calc(var(--header-height) + 56px) 130px;
  }

  &__wrap {
    padding-block: 170px 132px;
    position: relative;
    @include media-breakpoint-down(md) {
      padding-block: 20px 60px;
    }
  }

  &__bg {
    max-width: 1410px;
    width: 100%;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: calc(var(--header-height) + 56px);
      left: calc(50% - 10px);
      z-index: -1;
      transform: translateX(-50%);
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    &-img {
      width: 100%;
    }
  }

  &__block {
    max-width: 1130px;
    margin: 0 auto;
  }
}
