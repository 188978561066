.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  &.center-box {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
  }
  &.relative {
    position: relative;
  }
  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.center-box {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width:540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width:720px
  }
}

@media (min-width: 992px) {
  .container {
    max-width:960px
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1250px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1360px;
  }
}
.row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;

  &.left-right {
    justify-content: space-between;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.right {
  justify-content: flex-end;
}
.center {
  justify-content: center;
}
.col {
  flex: 1 0 0%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-50 {
  @extend .col;
  display: inline-block;
  width: 50%;
  &.right {
    justify-content: flex-end;
  }
  &.left {
    justify-content: flex-start;
  }
  @media (max-width: 991px) {
    width: 100%;
    &.right {
      justify-content: flex-start;
    }
  }
}
.col-30 {
  @extend .col;
  display: inline-block;
  width: 30%;
}
.col-70 {
  @extend .col;
  display: inline-block;
  width: 70%;
}

.col-30 {
  @extend .col;
  display: inline-block;
  width: 30%;

}
.col-70 {
  @extend .col;
  display: inline-block;
  width: 70%;
}