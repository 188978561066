.testimonials {
  padding: 40px 0 80px 0;
  position: relative;
  @include media-breakpoint-down(sm) {
    padding: 40px 0;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    @media (max-width: 767px) {
      margin: 0 auto;
      font-size: 32px;
    }
  }

  &__row {
    display: flex;
    width: calc(100% + 30px);
    margin-inline: -15px;
    @include media-down(1200) {
      width: calc(100% + 20px);
      margin-inline: -10px;
    }
    @include media-down(992) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__col {
    flex: 0 0 33.33333%;
    padding-inline: 15px;
    @include media-down(1200) {
      padding-inline: 10px;
    }
    @include media-down(992) {
      flex: 0 0 50%;
      max-width: 450px;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .swiper-wrapper {
    @include media-up(1024) {
      display: flex;
      width: calc(100% + 30px);
      margin-inline: -15px;
      transform: none;
      @include media-down(1200) {
        width: calc(100% + 20px);
        margin-inline: -10px;
      }
      @include media-down(768) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .swiper-slide {
    height: auto;
    @include media-up(1024) {
      flex: 0 0 33.33333%;
      padding-inline: 15px;
      @include media-down(1200) {
        padding-inline: 10px;
      }
      @include media-down(768) {
        flex: 0 0 50%;
        max-width: 450px;
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }

  &__card {
    width: 100%;
    min-height: 100%;
    padding: 16px;
    background: #edf0f2;
    border-radius: 8px;

    //&:hover {
    //  @include media-up(1025) {
    //    .testimonials__card-video-box__img {
    //      filter: saturate(1);
    //
    //      &.low-contrast {
    //        filter: saturate(.7);
    //      }
    //    }
    //  }
    //}

    &-row {
      display: flex;
      // gap: 32px;
      height: 100%;
      flex-direction: column;
      // @include media-breakpoint-down(md) {
      //   gap: 16px;
      // }
      // @include media-breakpoint-down(sm) {
      //   flex-direction: column;
      //   gap: 32px;
      // }
    }

    &-item {
      &:not(:last-child) {
        margin-bottom: 32px;
      }

      // &:first-child {
      //   flex: 0 0 42.31943%;
      //   width: 42.31943%;
      //   aspect-ratio: 1/1;
      //   @include media-breakpoint-down(xl) {
      //     flex: 0 0 50.31943%;
      //     width: 50.31943%;
      //   }
      //   @include media-breakpoint-down(sm) {
      //     flex: 0 0 100%;
      //     width: 100%;
      //   }
      // }

      // &:last-child {
      //   flex: 0 0 55.40681%;
      //   width: 55.40681%;
      //   height: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   gap: 30px;
      //   @include media-breakpoint-down(xl) {
      //     flex: 0 0 45.40681%;
      //     width: 45.40681%;
      //   }
      //   @include media-breakpoint-down(sm) {
      //     flex: 0 0 100%;
      //     width: 100%;
      //     flex-direction: column-reverse;
      //   }
      // }
    }

    &-video-box {
      width: 100%;
      aspect-ratio: 1.06/1;
      position: relative;
      border-radius: 8px;
      overflow: hidden;

      iframe,
      video,
      img,
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }

      img,
      &__img {
        z-index: 1;
        filter: saturate(0.1);
        transition: all 0.4s ease;
      }

      video {
        z-index: 2;

        &::-webkit-media-controls {
          display: none;
        }
      }

      button {
        display: block;
        padding: 0;
        width: 64px;
        height: 64px;
        position: absolute;
        right: 8px;
        bottom: 8px;
        z-index: 3;
        border-radius: 50%;
        background-color: $white;
        transition: all 0.35s ease;

        &:hover {
          @include media-up(1025) {
            transform: scale(1.1);
          }
        }

        &.active {
          &:before,
          &:after {
            width: 10px;
            height: 40%;
            top: 50%;
            transform: translateY(-50%);
            background-color: $black;
          }

          &:before {
            left: 30%;
          }

          &:after {
            right: 30%;
          }
        }

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          transition: all 0.3s ease;
          pointer-events: none;
        }

        &:not(.active):before,
        &:not(.active):after {
          width: 0;
          height: 0;
          position: absolute;
          top: 50%;
          left: 56%;
          transform: translate(-50%, -50%);
          border-style: solid;
          border-width: 12px 0 12px 20px;
          border-color: transparent transparent transparent $black;
        }
      }
    }

    &-text {
      // max-height: 323px;
      // min-height: 323px;
      // overflow-y: auto;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      color: $black;
      @include media-breakpoint-down(lg) {
        // font-size: 16px;
        // max-height: 295px;
        // min-height: auto;
      }
      // @include media-breakpoint-down(md) {
      //   max-height: 150px;
      //   min-height: 150px;
      // }

      p {
        @include inherit;
      }
    }

    &-author {
      margin-bottom: 24px;
      &-name,
      &-company {
        display: block;
        width: fit-content;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        color: $black;
      }

      &-name {
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 10px;
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }

      &-company {
        padding: 4px 10px;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        background: $white;
        border-radius: 4px;
      }
    }
  }
}

.nav-item {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  background: #ffffff;
  border-radius: 50%;
  display: inline-flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.35s ease;
  @include media-breakpoint-down(sm) {
    width: 56px;
    min-width: 56px;
    height: 56px;
    min-height: 56px;
  }
  @include media-down(355) {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
  }

  &:before {
    content: '';
    width: 19px;
    height: 13px;
    display: inline-block;
    transition: all 0.35s ease-in-out;
  }

  &:hover {
    box-shadow: rgb(100 100 111 / 10%) 0px 2px 15px 0px;
    transform: scale(1.01);
  }
}

.testimonials-slider {
  width: 100%;
  @media (max-width: 991px) {
    padding-bottom: 10px;
  }

  &__wrapper {
    .swiper-slide {
      height: auto;
    }

    .swiper-wrapper {
      margin-bottom: 40px;
    }

    .testimonials-slider__progressbar {
      width: 100%;
      height: 4px;
      position: relative;

      @include media-up(1024) {
        display: none;
      }

      &-static-line,
      &-dynamic-line {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &-static-line {
        width: 100%;
        background: #edf0f2;
        border-radius: 10px;
      }

      &-dynamic-line {
        width: 33.33333%;
        background: #000000;
        opacity: 0.2;
        border-radius: 10px;
        transition: width 0.3s ease-out;
      }
    }
  }

  &__nav {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      gap: 8px;
    }
  }

  &__prev {
    @extend .nav-item;

    &:before {
      background: url('../svg/prev.svg') 0 0 no-repeat;
      background-size: contain;
    }
  }

  &__next {
    @extend .nav-item;

    &:before {
      background: url('../svg/next.svg') 0 0 no-repeat;
      background-size: contain;
    }
  }

  &__item {
    background: var(--grey);
    border-radius: 8px;
    padding: 16px;
    width: 286px;
    margin-right: 8px;
    overflow: hidden;
    @media (max-width: 991px) {
      width: 286px;
      padding: 16px 16px 21px;
    }
    @media (min-width: 992px) {
      width: 600px;
      padding: 16px 16px 21px;
    }

    @media screen and (min-width: 1280px) {
      width: 983px;
      height: 458px;
    }
  }
}

//.testimonials.type-1 {
//  .testimonials__card-video-box__img {
//    filter: saturate(.1);
//  }
//}
