:root {
  --fontGilroyRegular: 'Gilroy', Helvetica, Arial, sans-serif;
  --fontInterRegular: 'Inter', Helvetica, Arial, sans-serif;
  --background-color: #{$light-grey};
  --main-text-color: #{$black};
  --green: #{$green};
  --green-hover: #{$green-hover};
  --grey: #{$grey};
  --red: #{$red};
  --red-primary: #{#FF5F5F};
  --grey-dark: #{$grey-dark};
  --grey-lines: #{$grey-lines};
  --white: #{$white};
  --black: #{$black};
  --form-gray: #{$form-grey};
  --grey-label: #{$grey-label};
  --grey-line-banner: #333333;
  --blue: #082170;
  --blue-dark: #0e1a35;
  --grey-items: #1E1E1E;
  --header-height: 105.58px;
  --footer-height: 361.69px;
  --window-width: 1440px;
}

body {
  font-family: 'Gilroy', sans-serif;
  font-weight: normal;
  line-height: 1.3;
  font-size: px(14);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: $white-primary;

  &.body-lock {
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button {
  background-color: initial;
  border: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea {
  resize: none;
}

section {
  position: relative;
}

input,
select,
button,
textarea {
  outline: none !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.content {
  flex: 1 0 auto;
}

// VW bootstrap grid system
.container {
  max-width: 1390px;
  padding-right: 15px;
  padding-left: 15px;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  margin-left: px(-15);
  margin-right: px(-15);
  @include media-breakpoint-down(xs) {
    margin-left: pxs(-15);
    margin-right: pxs(-15);
  }
}

.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  padding-left: px(15);
  padding-right: px(15);
  @include media-breakpoint-down(xs) {
    padding-left: pxs(15);
    padding-right: pxs(15);
  }
}
