.main-title {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $white;
}
