$first-color: rgba(50, 251, 94, 0.4);

$colors: $first-color, rgba(50, 251, 178, 0.4), rgba(50, 251, 177, 0.4), rgba(50, 251, 239, 0.4),
  rgba(51, 251, 239, 0.4), rgba(50, 222, 251, 0.4), rgba(20, 185, 255, 0.4);

.table-steps {
  max-width: 824px;
  width: 100%;
  margin: 0 auto;
  padding-block: 50px;
  position: relative;
  @include media-breakpoint-down(md) {
    padding-block: 25px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 5px;
    }

    &:first-child {
      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background: $first-color;
        @include media-breakpoint-down(md) {
          top: -25px;
        }
      }

      .table-steps__icon-box {
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 50px;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          background: $first-color;
          @include media-breakpoint-down(md) {
            height: 25px;
          }
        }
      }
    }

    &:not(:first-child) {
      @include media-breakpoint-up(md) {
        margin-top: -12px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
    }

    &:not(:last-child) {
      .table-steps__card {
        @include media-breakpoint-down(md) {
          &:before {
            content: '';
            display: block;
            width: 1px;
            height: 20px;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    @each $color in $colors {
      $index: index($colors, $color);
      &:nth-child(#{$index}) {
        .table-steps__icon-box {
          border-color: $color;

          &:before {
            background-color: $color;
          }

          .icon {
            fill: $color;

            .stroke {
              fill: none;
              stroke: $color;
            }
          }
        }

        .table-steps__card {
          border-color: $color;

          &:before {
            @include media-breakpoint-down(md) {
              background-color: $color;
            }
          }

          &-number {
            color: $color;
          }
        }
      }
    }

    &:nth-child(odd) {
      .table-steps__icon-box {
        &:before {
          @include media-breakpoint-up(md) {
            right: 100%;
          }
        }
      }

      .table-steps__card {
        left: 0;
        border-width: 1px 1px 1px 4px;
      }
    }

    &:nth-child(even) {
      .table-steps__icon-box {
        &:before {
          @include media-breakpoint-up(md) {
            left: 100%;
          }
        }
      }

      .table-steps__card {
        right: 0;
        border-width: 1px 4px 1px 1px;
        @include media-breakpoint-down(md) {
          border-width: 1px 1px 1px 4px;
        }
      }
    }
  }

  &__icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    position: relative;
    z-index: 5;
    border: 1px solid;
    border-radius: 50%;
    @include media-breakpoint-down(md) {
      background-color: $grey-100;
      width: 55px;
      height: 55px;
    }

    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-down(md) {
        width: 1px;
        height: 6px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .icon {
      width: 32px;
      height: 32px;

      &-steps-icon-2 {
        width: 37px;
        height: 32px;
      }

      &-steps-icon-9 {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__card {
    max-width: 312px;
    padding: 16px 24px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    border: 1px solid;
    border-radius: 8px;
    position: relative;
    z-index: 10;
    background-color: $grey-100;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-down(lg) {
        max-width: 39%;
      }
    }
    @include media-breakpoint-down(md) {
      max-width: 450px;
    }

    &-number {
      margin-bottom: 8px;
      font-weight: 300;
      font-size: 32px;
      line-height: 120%;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    &-title {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      color: $white;
      @include media-breakpoint-down(lg) {
        margin-bottom: 4px;
        text-align: center;
        font-size: 16px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: $white;
      opacity: 0.5;
      letter-spacing: 0.05em;
      @include media-breakpoint-down(md) {
        font-size: 13px;
        text-align: center;
      }
    }
  }
}
