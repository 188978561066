.services-benefits {
  width: 100%;
  height: 645px;
  overflow: hidden;
  background: url('../img/upload/our-services/benefits-bg.jpg') 0 0 / cover no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 991px) {
    height: 645px;
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba($black, 0.8);
  }

  &__wrap {
    position: relative;
    z-index: 10;
  }

  &__circle {
    position: relative;
    z-index: 1;
    width: 396px;
    height: 396px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    @media (max-width: 767px) {
      width: 290px;
      height: 290px;
    }
    @include media-breakpoint-down(sm) {
      width: 250px;
      height: 250px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 7px;
      display: inline-block;
      width: 382px;
      height: 382px;
      z-index: -1;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.4);
      opacity: 0.4;
      @media (max-width: 767px) {
        width: 280px;
        height: 280px;
      }
      @include media-breakpoint-down(sm) {
        width: 240px;
        height: 240px;
        top: 3.5px;
        left: 3.5px;
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: var(--white);
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 0;
    width: 100%;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
}

.services-benefits-list {
  z-index: 2;
  color: var(--white);

  &.four-items {
    .services-benefits-list__item {
      @include media-breakpoint-down(sm) {
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
      }

      .icon {
        &-services-benefits-1 {
          @include media-breakpoint-down(sm) {
            width: 25px;
            height: 19px;
          }
        }

        &-services-benefits-2 {
          @include media-breakpoint-down(sm) {
            width: 20px;
            height: 21px;
          }
        }

        &-services-benefits-3 {
          @include media-breakpoint-down(sm) {
            width: 19px;
            height: 19px;
          }
        }

        &-services-benefits-4 {
          @include media-breakpoint-down(sm) {
            width: 17px;
            height: 17px;
          }
        }

        &-services-benefits-5 {
          @include media-breakpoint-down(sm) {
            width: 16px;
            height: 19px;
          }
        }

        &-services-benefits-6 {
          @include media-breakpoint-down(sm) {
            width: 22px;
            height: 14px;
          }
        }
      }

      .services-benefits-list__text {
        @include media-breakpoint-down(md) {
          font-size: 13px;
        }
      }

      &.top {
        .services-benefits-list__text {
          text-align: center;
        }
      }

      &.left {
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        @include media-down(360) {
          transform: translate(-30%, -50%);
        }

        .services-benefits-list__text {
          text-align: right;
          @include media-breakpoint-down(lg) {
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 15px);
          }
          @include media-breakpoint-down(sm) {
            width: 150px;
            white-space: initial;
            transform: translateX(-40%);
            br {
              display: none;
            }
          }
          @include media-down(360) {
            width: 100px;
          }
        }
      }

      &.right {
        right: 0;
        top: 50%;
        transform: translate(30%, -50%);

        .services-benefits-list__text {
          text-align: left;
          @include media-breakpoint-down(lg) {
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 15px);
          }
          @include media-breakpoint-down(sm) {
            width: 150px;
            white-space: initial;
            transform: translateX(-55%);
            br {
              display: none;
            }
          }
          @include media-down(360) {
            width: 100px;
          }
        }
      }

      &.bottom {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);

        .services-benefits-list__text {
          text-align: center;
          top: calc(100% + 14px);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  &__icon {
    width: 100%;
    height: 100%;
    z-index: 5;
    position: relative;
    border-radius: 50%;
    background: linear-gradient(36.25deg, #1f1e1e 19.12%, #1f1e1e 100%);
    border: 1px solid var(--green);
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .icon {
      fill: $white;

      &-services-benefits-1 {
        width: 35px;
        height: 26px;
      }

      &-services-benefits-2 {
        margin-top: -2px;
        margin-right: -2px;
        width: 23px;
        height: 24px;
      }

      &-services-benefits-3 {
        width: 25px;
        height: 25px;
      }

      &-services-benefits-4 {
        width: 21px;
        height: 21px;
      }

      &-services-benefits-5 {
        width: 20px;
        height: 24px;
      }

      &-services-benefits-6 {
        width: 27px;
        height: 17px;
      }
    }
  }

  &__item {
    width: 64px;
    min-width: 64px;
    height: 64px;
    min-height: 64px;
    position: absolute;

    .services-benefits-list__text {
      width: fit-content;
      position: absolute;
      white-space: nowrap;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: $white;
      @include media-breakpoint-down(md) {
        width: fit-content;
        text-align: center;
        font-size: 16px;
      }
    }

    &.top {
      left: 50%;
      top: -30px;
      transform: translateX(-50%);

      .services-benefits-list__text {
        text-align: center;
        bottom: calc(100% + 14px);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.left {
      left: 30px;
      bottom: 30px;
      @include media-breakpoint-down(md) {
        left: 0;
        bottom: 0;
      }
      @include media-down(390) {
        left: 10px;
      }

      .services-benefits-list__text {
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 15px);
        text-align: right;
        @include media-breakpoint-down(md) {
          left: 50%;
          transform: translateX(-50%);
          top: calc(100% + 15px);
          text-align: center;
        }
        @include media-down(390) {
          width: 140px;
          white-space: initial;
          br {
            display: none;
          }
        }
      }
    }

    &.right {
      right: 30px;
      bottom: 30px;
      @include media-breakpoint-down(md) {
        right: 0;
        bottom: 0;
      }
      @include media-down(390) {
        right: 10px;
      }

      .services-benefits-list__text {
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 15px);
        @include media-breakpoint-down(md) {
          left: 50%;
          transform: translateX(-50%);
          top: calc(100% + 15px);
        }
        @include media-down(390) {
          width: 140px;
          white-space: initial;
          br {
            display: none;
          }
        }
      }
    }
  }
}
