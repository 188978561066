.cookies {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 8px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, .3);
  transition: opacity .4s ease;
  @include media-breakpoint-down(lg) {
    align-items: center;
    justify-content: center;
  }

  &.hidde {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    .cookies__content {
      transform: translateX(50%);
    }
  }

  &__content {
    max-width: 312px;
    padding: 24px;
    background-color: var(--blue);
    border-radius: 8px;
    transition: all .4s ease;

    span {
      display: block;
      margin-bottom: 23px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--white);
    }

    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--white);
      text-decoration: underline;
      transition: color .3s ease;

      &:hover {
        @include media-up(1025) {
          color: $green;
        }
      }
    }

    button {
      display: block;
      width: 100%;
      padding: 10px 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--white);
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 8px;
      transition: all .3s ease;

      &:hover {
        @include media-up(1025) {
          background-color: var(--white);
          border-color: var(--white);
          color: var(--blue);
        }
      }
    }
  }
}
