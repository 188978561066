.clients {
  width: 100%;
  display: flex;
  position: relative;
  margin-top: -74px;
  margin-bottom: 62px;
  z-index: 4;
  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }

  &__wrapper {
    border-radius: 8px;
    background: var(--white);
    position: relative;
    overflow: hidden;
    border: 1px solid var(--grey);
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__slider-random {
    display: flex;
    align-items: center;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    padding-left: 50px;

    &.slider-part-1 {
      .clients__img-wrap__img {
        &.img-part-1 {
          opacity: 1;
        }

        &.img-part-2 {
          opacity: 0;
        }
      }
    }

    &.slider-part-2 {
      .clients__img-wrap__img {
        &.img-part-1 {
          opacity: 0;
        }

        &.img-part-2 {
          opacity: 1;
        }
      }
    }
  }

  &__img-wrap {
    flex: 0 0 14.28571%;
    width: 14.28571%;
    height: 100%;
    position: relative;

    &:not(:last-child) {
      border-right: 1px solid #EDF0F2;
    }

    &__img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity .75s ease;
    }
  }

  &__slider {
    position: relative;
    width: 100%;
    padding-left: 50px;
    @include media-breakpoint-down(lg) {
      height: 100%;
      padding-left: 0;
      padding-top: 34px;
    }
  }

  &__slide {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    opacity: 0 !important;
    transition: opacity .75s ease;

    &.swiper-slide-active {
      opacity: 1 !important;
    }

    &-img-wrap {
      flex: 0 0 14.28571%;
      width: 14.28571%;
      @include media-breakpoint-down(md) {
        flex: 0 0 33.33333%;
        width: 33.33333%;
        &:last-child {
          display: none;
        }
      }

      &:not(:last-child) {
        @include media-breakpoint-up(md) {
          border-right: 1px solid #EDF0F2;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @include media-breakpoint-down(md) {
          border-bottom: 1px solid #EDF0F2;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        @include media-breakpoint-down(md) {
          border-right: 1px solid #EDF0F2;
        }
      }
    }

    &__img {
      width: 100%;
    }
  }

  &__title {
    background: var(--grey);
    border-radius: 8px 8px 0 0;
    position: absolute;
    z-index: 10;
    left: -50px;
    height: 50px;
    line-height: 50px;
    bottom: 50px;
    text-align: center;
    width: 150px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--main-text-color);
    transform: rotate(-90deg);

    @include media-breakpoint-down(lg) {
      transform: rotate(0);
      height: 34px;
      line-height: 34px;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 8px 8px 0 0;
    }
  }
}
