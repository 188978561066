.button {
  font-family: var(--fontGilroyRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: none;
  color: var(--white);
  cursor: pointer;
  transition: all 0.3s ease-in;

  &--getintouch {
    padding: 16px 24px;
    min-height: 51px;
    background: var(--green);
    border-radius: 4px;
    @media (max-width: 991px) {
      display: none;
    }

    &:hover {
      background: var(--green-hover);
    }

    &.mobile {
      @media (max-width: 991px) {
        display: block;
        width: 100%;
        margin-top: 25px;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.explore-all {
    width: 100%;
    height: 72px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
  }

  &.explore-more {
    width: 100%;
    height: 56px;
    border-top: 1px solid rgba(175, 175, 175, 0.3);
    padding: 18px 24px;
    font-weight: 400;
    font-size: 16px;
    color: var(--white);
    justify-content: flex-start;

    &:hover {
      outline: none;
    }
  }

  &.submit {
    width: 100%;
    max-width: 320px;
    height: 51px;
    padding: 16px 68px;
    background: var(--green);
    border-radius: 4px;

    &:hover {
      background: var(--green-hover);
    }

    @media (min-width: 768px) {
      width: 188px;
    }
  }
}

.btn {
  display: block;
  width: fit-content;
  padding: 16px 24px;
}

.btn-primary {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $white;
  transition: all 0.5s;
  position: relative;
  @include media-up(1025) {
    &:hover {
      background-color: transparent;

      span {
        color: $white;
      }

      &:after {
        opacity: 1;
        transform: scale(1, 1);
      }

      &:before {
        opacity: 0;
        transform: scale(0.5, 0.5);
      }
    }

    &:active {
      &:after {
        border-color: transparent;
        background-color: $green;
      }
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 4px;
    pointer-events: none;
  }

  &::after {
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.5);
    transform: scale(1.2, 1.2);
  }

  &::before {
    background-color: $green;
    transition: all 0.3s;
  }

  span {
    @include inherit;
    position: relative;
    z-index: 5;
  }
}

.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $white;
  transition: all 0.5s;
  position: relative;
  @include media-up(1025) {
    &:hover {
      color: $black;

      &:after {
        opacity: 0;
        transform: scale(.9);
      }

      &:before {
        opacity: 1;
        transform: scale(1);
      }

      .icon-arrow-right {
        stroke: $black;
      }
    }

    &:active {
      &:after {
        border-color: transparent;
        background-color: transparent;
      }
    }
  }
  @include media-down(1025) {
    &:hover {
      color: $white;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 4px;
    pointer-events: none;
  }

  &::after {
    transition: all 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.4);
    transform: scale(1);
    border-radius: 8px;
  }

  &::before {
    opacity: 0;
    background-color: $white;
    transition: all 0.3s;
    transform: scale(.9);
    border-radius: 8px;
  }

  span {
    @include inherit;
    position: relative;
    z-index: 5;
  }

  .icon-arrow-right {
    width: 15px;
    height: 10px;
    stroke: $white;
    transition: all .3s;
    position: relative;
    z-index: 5;
  }
}

.btn-green {
  padding: 26px 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $green;
  border: 1px solid $green;
  border-radius: 8px;
  transition: all .2s ease;

  &:hover {
    @include media-up(1025) {
      border-color: $black;

      .icon-arrow-right {
        stroke: $black;
      }

      span {
        color: $black;
      }
    }
  }

  .icon-arrow-right {
    width: 15px;
    height: 10px;
    stroke: $green;
    transition: all .2s ease;
  }

  span {
    @include inherit;
    transition: all .2s ease;
  }
}

.link-primary {
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $white;

  &:hover {
    @include media-up(1025) {
      color: $green;
      .icon {
        stroke: $green;
        transform: translateX(5px);
      }
    }
  }

  span {
    @include inherit;
    transition: color .2s ease;
  }

  .icon {
    width: 10px;
    height: 15px;
    fill: none;
    stroke: $white;
    transition: all .3s ease;
  }
}
