.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  transition: background-color .4s ease, top .4s ease;

  .container {
    max-width: calc(1384px + 30px);
  }

  .btn-primary {
    height: 51px;
    padding: 16px 24px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: $white;

    &:hover {
      @include media-up(1025) {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
  }

  &.hide {
    top: calc(var(--header-height) * -1.1);
  }

  &.bg-black {
    background: var(--black);
  }

  &.bg-black-static {
    background: var(--black);
  }

  &__nav-trigger {
    display: block;
    width: 32px;
    min-width: 32px;
    height: 32px;
    padding: 0;
    position: relative;
    z-index: 2000;
    @include media-breakpoint-up(lg) {
      display: none;
    }

    &.active {
      .icon {
        background-color: transparent;

        &:before,
        &:after {
          top: 50%;
          left: 50%;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    .icon {
      top: 50%;
      transition: background-color .2s ease;

      &,
      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        background-color: $white;
        transform-origin: center center;
        transition: all .3s ease;
      }

      &:before {
        top: -6px;
      }

      &:after {
        bottom: -6px;
      }
    }
  }

  &__wrap {
    padding-block:  16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding-block: 22px;
    }

    &:before {
      content: '';
      display: block;
      width: calc(100% + 24px);
      height: 1px;
      position: absolute;
      bottom: 0;
      left: -24px;
      background-color: rgba(255, 255, 255, 0.2);
      @include media-breakpoint-down(lg) {
        left: -15px;
        width: calc(100% + 30px);
      }
    }
  }

  &__logo {
    max-width: 203px;
    width: 100%;
    position: relative;
    z-index: 2000;

    &-img {
      width: 100%;
    }
  }

  &__logo-clutch {
    margin-right: 64px;
    max-width: 70px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      max-width: 110px;
      margin: 0 auto;
    }

    &-img {
      width: 100%;
    }
  }

  .navigation__list {
    margin-right: 64px;
  }

  &-container {
    min-height: 71px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 991px) {
      max-width: 100%;
      width: 100%;
      padding: 0 16px 0 16px;
    }

    @media (min-width: 992px) {
      max-width: 960px
    }
    @media (min-width: 1280px) {
      max-width: 1250px;
    }

    @media (min-width: 1440px) {
      max-width: 1408px;
    }

    .row {
      width: 100%;
    }
  }

  &-left {
    max-width: 300px;
    display: inline-flex;
    @media (max-width: 991px) {

    }
  }

  &-right {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 270px);
    justify-content: flex-end;
  }
}
