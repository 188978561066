.services {
  padding: 10px 0 72px 0;
  @include media-breakpoint-down(lg) {
    padding: 10px 0 32px 0;
  }

  &-row {
    margin-bottom: 40px;
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 32px;
    }
  }

  &-left {
    display: inline-block;
    width: 28%;
    max-width: 340px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  &-right {
    display: inline-block;
    width: calc(100% - 340px);
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    @include media-breakpoint-down(lg) {
      margin-bottom: 16px;
      font-size: 32px;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 120%;
    @media (max-width: 767px) {
      font-size: 20px;
    }
    @media (max-width: 991px) {
      font-size: 21px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  .services-tab {
    position: relative;
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      aspect-ratio: 1.7/1;
      @include media-down(1080) {
        aspect-ratio: 1.5/1;
      }
    }
    @include media-breakpoint-down(lg) {
      margin-inline: -15px;
      width: calc(100% + 30px);
    }

    &:after {
      content: '';
      display: block;
      width: 33.33333%;
      height: calc(100% - 16px);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 20;
      transform: translate(-50%, -50%);
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      pointer-events: none;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    &__row {
      display: flex;
      height: 100%;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    &__item {
      padding: 25px 30px;
      height: 100%;
      flex: 0 0 33.33333%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include media-down(1080) {
        padding: 15px 20px;
      }
      @include media-breakpoint-down(lg) {
        max-width: 550px;
        margin: 0 auto;
        position: relative;
        transition: all 1s ease-out;
      }
      // @include media-breakpoint-down(lg) {
      //   position: relative;
      //   min-height: 206px;
      //   max-height: 206px;
      //   transition: all 1s ease-out;
      // }

      &.active {
        @include media-breakpoint-down(lg) {
          max-height: 1000px;
          overflow-y: auto;
        }

        .services-tab__btn {
          opacity: 1;

          &:before {
            width: 100%;
          }
        }

        .services-tab__content {
          opacity: 1;
          pointer-events: all;
        }

        .services-tab__card {
          transform: translateY(0);
          @include media-breakpoint-down(lg) {
            opacity: 1;
          }
        }
      }
    }

    &__btn {
      padding: 0;
      margin-right: auto;
      display: block;
      width: fit-content;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 120%;
      color: $white;
      opacity: 0.4;
      transition: opacity 0.3s ease;
      position: relative;
      z-index: 10;

      @include media-down(1380) {
        font-size: 32px;
      }

      @include media-down(1080) {
        font-size: 28px;
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }

      &:hover {
        @include media-up(1025) {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        transition: width 0.3s ease;
      }
    }

    &__content {
      transition: opacity 1s;
      @include media-breakpoint-up(lg) {
        opacity: 0;
        pointer-events: none;
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: rgba(0, 0, 0, 0.3);
        pointer-events: none;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
        transition: opacity 0.5s ease;
        @include media-breakpoint-down(lg) {
          width: 100%;
          height: 100%;
          min-height: 205px;
          position: static;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
        }
      }
    }

    &__card {
      padding: 16px 24px 8px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 8px;
      transition: all 0.8s ease-out;
      transform: translateY(25%);
      @include media-breakpoint-down(lg) {
        border-radius: 0 0 8px 8px;
        opacity: 1;
        transform: translateY(0);
        transition: all 0.4s ease-out;
        background: rgba(0, 0, 0, 1);
      }
      // @include media-breakpoint-down(lg) {
      //   opacity: 0;
      //   transition: all 0.4s ease-out;
      // }

      &-service-title {
        margin-bottom: 16px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
        color: $white;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }

      &-text {
        margin-bottom: 40px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        color: $white;
        font-size: 18px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }

      &-title {
        margin-bottom: 6px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $white;
        opacity: 0.5;
      }

      &-list {
        margin-bottom: 20px;

        &-item {
          display: flex;
          align-items: center;
          gap: 12px;

          &:not(:last-child) {
            margin-bottom: 5px;
          }

          span {
            font-family: 'Gilroy', sans-serif;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            color: $white;
            font-size: 18px;
            @include media-breakpoint-down(lg) {
              font-size: 16px;
            }
          }

          .icon-done {
            width: 18px;
            height: 12px;
            fill: none;
            stroke: $green;
          }
        }
      }

      &-link {
        display: flex;
        align-items: center;
        gap: 15px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: $white;

        &:hover {
          @include media-up(1025) {
            color: $green;
            .icon {
              stroke: $green;
              transform: translateX(5px);
            }
          }
        }

        span {
          @include inherit;
          transition: color 0.2s ease;
        }

        .icon {
          width: 10px;
          height: 15px;
          fill: none;
          stroke: $white;
          transition: all 0.3s ease;
        }
      }

      &-footer {
        padding: 20px 0;
        border-top: 1px solid rgba($white, 0.1);
      }
    }
  }
}
