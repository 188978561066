// Hide classes
.hideLeft {
  transform: translateX(-100px);
  transition: all .5s ease-out;
  opacity: 0;
}

.hideRight {
  transform: translateX(100px);
  transition: all .5s ease-out;
  opacity: 0;
}

.hideUp {
  transform: translateY(-50px);
  transition: all .5s ease-out;
  opacity: 0;
}

.hideDown {
  transform: translateY(50px);
  transition: all .5s ease-out;
  opacity: 0;
}

.hideScale {
  transform: scale(.85);
  transition: all .5s ease-out;
  opacity: 0;
}

// Show up classes
.fadeUp {
  transform: translateY(0);
  opacity: 1;
}

.fadeDown {
  transform: translateY(0);
  opacity: 1;
}

.scaleIn {
  transform: scale(1);
  opacity: 1;
}

@for $i from 1 through 10 {
  .td-#{$i}00 {
    transition-delay: #{$i}00ms;
  }
}
