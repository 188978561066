.aboutus-banner {
  position: relative;
  @include media-breakpoint-down(md) {
    height: 922px;
  }
}

.aboutus-bottom-banner {
  background: url('../img/upload/about-us-page/aboutus-bottom-banner.png') 0 0 no-repeat;
  min-height: 300px;
  background-size: cover;
  width: 100%;
  position: relative;
  z-index: -1;
  @include media-breakpoint-down(lg) {
    min-height: 202px;
  }
}

.aboutus-top-banner {
  height: 100vh;
  max-height: 757px;
  background-color: var(--black);
  background-image: url('../img/upload/about-us-page/mapbanner.png');
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: contain;
  @include media-breakpoint-down(sm) {
    max-height: 757px;
  }
  @include media-breakpoint-down(sm) {
    background-size: 157%;
    background-position: 53% 2%;
  }

  &__wrap {
    padding-top: calc(167px + var(--header-height));
    position: relative;
    height: 100vh;
    max-height: 757px;
    z-index: 10;
    @include media-breakpoint-down(lg) {
      max-height: 737px;
      padding-top: calc(48px + var(--header-height));
    }

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: calc(var(--header-height) * -1);
      left: 41.8%;
      background-color: $white;
      opacity: 0.2;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__title {
    margin-left: 115px;
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    color: var(--white);
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
    @include media-breakpoint-down(sm) {
      font-size: 40px;
    }
    @include media-down(360) {
      font-size: 36px;
    }
  }
}

.whoweare {
  position: absolute;
  left: 0;
  bottom: -50px;
  background: var(--grey);
  padding: 50px 50px 50px 115px;
  color: var(--black);
  transition-delay: 250ms;
  @media (min-width: 991px) {
    width: 75%;
  }
  @media (min-width: 1440px) {
    width: 880px;
    padding: 80px 66px 80px 115px;
  }
  @include media-breakpoint-down(lg) {
    padding: 24px 15px;
    width: 75%;
    bottom: -50px;
  }
  @include media-breakpoint-down(md) {
    width: calc(100% - 15px);
  }
  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    background: inherit;
  }

  &__title {
    margin-bottom: 32px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #082170;
  }

  &__row {
    margin-bottom: 20px;
    display: flex;
  }

  &__text {
    margin-right: 53px;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    @include media-breakpoint-down(lg) {
      font-size: 16px;
    }
  }

  &__img {
    display: block;
    max-width: 113px;
    min-width: 113px;
    width: 100%;
    height: fit-content;
    @include media-down(1024) {
      display: none;
    }
  }
}
