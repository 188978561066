.common-cover-section {
  padding-bottom: 80px;
  @include media-breakpoint-down(sm) {
    padding-bottom: 50px;
  }

  &.pb-65 {
    padding-bottom: 65px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 50px;
    }
  }

  &.pb-120 {
    padding-bottom: 120px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 50px;
    }
  }

  .container {
    max-width: 1160px;
  }

  .btn-green {
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      padding: 20px 40px;
    }
  }

  &__wrap {
    min-height: 396px;
    border-top: 6px solid var(--green);
    margin-top: -50px;
    background-color: var(--white);
  }

  &__done {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 32px auto;
    border: 1px solid #EDF0F2;
    border-radius: 50%;

    &.failure {
      border-color: var(--red-primary);
    }

    .icon {
      &-done_2 {
        width: 25px;
        height: 17px;
        fill: none;
        stroke: $green;
      }

      &-failure {
        width: 32px;
        height: 32px;
        fill: none;
        stroke: var(--red-primary);
      }
    }
  }

  &__block {
    max-width: 652px;
    margin: 0 auto;
    padding-block: 66px 78px;
    @include media-breakpoint-down(sm) {
      padding-block: 45px 60px;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 36px;
    }
  }

  &__text {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  &__content {
    padding: 66px 15px 88px;
    max-width: 930px;
    margin: 0 auto;
    color: #000;
    @include media-breakpoint-down(sm) {
      padding-block: 35px 60px;
    }

    h2 {
      font-weight: 400;
      font-size: 40px;
      line-height: 120%;
      @include media-breakpoint-down(sm) {
        font-size: 32px;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
    }

    strong {
      margin-right: 2px;
      font-weight: 600;
    }

    div {
      overflow-x: auto;

      > ol {
        padding: 0;
        margin: 0;

        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
        }
      }

      > ul {
        padding-left: 50px;
        @include media-breakpoint-down(sm) {
          padding-left: 25px;
        }
      }

      > table {
        min-width: 600px;
      }
    }

    ol, ul {
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
      }
    }

    ol {
      padding-left: 25px;
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }

      li {
        list-style-type: decimal;
      }
    }

    ul {
      padding-left: 25px;
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }

      li {
        list-style: initial;
      }
    }

    blockquote {
      padding: 20px 15px;
      background-color: #F9F9F9;
      border-width: 0 3px 0 3px;
      border-style: solid;
      border-color: $green;
    }

    table {
      th, td {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 25.22222%;
        }

        &:nth-child(3) {
          width: 41.44444%;
        }

        a {
          text-decoration: none;
        }
      }


      thead {
        border-top: 2px solid #77C663;
        background-color: #F9F9F9;

        th {
          vertical-align: center;
          padding: 12px 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 120%;
          @include media-breakpoint-down(md) {
            padding: 5px 10px;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #EDF0F2;
        }

        td {
          vertical-align: top;
          padding: 20px;
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
          @include media-breakpoint-down(md) {
            padding: 5px;
          }
        }
      }

      tfoot {
        td {
          padding-top: 32px;
          width: 100%;
        }
      }
    }

    a {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      transition: color .2s ease;
      overflow-wrap: anywhere;
      text-decoration: underline;

      &:hover {
        color: $green;
      }
    }
  }
}
