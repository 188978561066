.social-list {
  display: flex;
  gap: 39px;
  border: none;
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    justify-content: center;
  }

  &.border {
    display: inline-flex;
    border: 1px solid var(--grey-items);
    padding: 0;
    margin: 0;
  }

  &__item {
    width: 64px;
    height: 64px;
    border-right: 1px solid var(--grey-items);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.small {
      width: 16px;
      height: 16px;
      border: none;
    }

    &:last-child {
      margin-right: 0;
      border-right: none;
    }

  }

  &__href {
    display: inline-block;
    cursor: pointer;

    .icon {
      width: 16px;
      height: 16px;
      fill: $white;
      transition: all .25s ease;

      &-linkedIn {
        width: 26px;
        height: 26px;
      }
    }

    &:hover {
      @include media-up(1025) {
        .icon {
          fill: $green;
        }
      }
    }
  }
}
