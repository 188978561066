.portfolio-page {
  .container {
    max-width: 1170px;
  }

  .projects {
    padding-top: 0;

    .row-gallery {
      width: calc(100% + 16px);
      margin-inline: -8px;
    }

    .col-gallery {
      padding-inline: 8px;
      flex: 0 0 50%;
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
      }

      .project {
        margin-bottom: 24px;

        &.type2 {
          height: 544px;
          @include media-breakpoint-down(lg) {
            height: 406px;
          }
        }

        &__name {
          @include media-breakpoint-down(lg) {
            margin-bottom: 14px;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.portfolio {
  padding-top: var(--header-height);
  background: $black url('../svg/project-bg.svg') 0 0 / contain no-repeat;

  .tabs {
    margin-bottom: 40px;
    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }


  .tabs__header {
    margin-bottom: 81px;

    &-btn {
      font-size: 24px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 45px;
    }
  }

  .projects {
    background-color: transparent;
  }

  &__banner {
    padding-top: 167px;
    @include media-breakpoint-down(lg) {
      padding-top: 50px;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: $white;
    @include media-breakpoint-down(lg) {
      margin-bottom: 16px;
      font-size: 40px;
    }
  }

  &__subtitle {
    margin-bottom: 42px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: $white;
    max-width: 781px;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      margin-bottom: 26px;
      font-size: 16px;
    }
  }

  &__upload-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 26px 20px;
    border: 1px solid $white;
    border-radius: 8px;
    @include media-breakpoint-down(lg) {
      margin-top: 24px;
      padding: 26px 20px;
    }

    &:hover {
      border-color: $green;

      span {
        color: $green;
      }

      .icon {
        stroke: $green;
      }
    }

    span,
    .icon {
      transition: all .2s ease;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: $white;
    }

    .icon {
      width: 15px;
      height: 11px;
      stroke: $white;
    }
  }
}
