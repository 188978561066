$white: #fff;
$white-primary: #F9F9F9;
$black: #000;
$black-secondary: #050505;

// Green
$green-primary: #77C663;
$green: #77C663;
$green-hover: #548f47;

// Red
$red: #FF5B28;

// Gray
$grey: #EDF0F2;
$grey-100: #272830;
$grey-200: #3B3C44;
$light-grey: #F9F9F9;
$grey-dark: #6B6B6B;
$grey-lines: #7A7A7A;
$grey-label: #D9D9D9;
$form-grey: #cccccc;
