.navigation {
  &.top {
    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1950;
      width: 100vw;
      height: 100vh;
      background-color: $black;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all .4s ease;

      &:before {
        content: '';
        display: block;
        width: 100vw;
        height: 55px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: inherit;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        .navigation__wrap {
          transform: translateX(0);
        }

        .d-1,
        .d-2,
        .d-3,
        .d-4,
        .d-5,
        .d-6 {
          transform: scale(1);
          opacity: 1;
        }
      }

      .d-1,
      .d-2,
      .d-3,
      .d-4,
      .d-5,
      .d-6 {
        transform: scale(.2);
        opacity: 0;
      }

      .d-1 {
        transition: all .3s ease-out;
      }
      .d-2 {
        transition: all .5s ease-out;
      }
      .d-3 {
        transition: all .7s ease-out;
      }
      .d-4 {
        transition: all .9s ease-out;
      }
      .d-5 {
        transition: all 1.1s ease-out;
      }
      .d-6 {
        transition: all 1.3s ease-out;
      }
    }

    .navigation__wrap {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(lg) {
        padding-block: 75px 30px;
        flex-direction: column;
        gap: 64px;
        width: 100%;
        min-height: 100%;
        max-height: 100%;
        overflow-y: auto;
        transition: all .4s ease;

        .btn-primary {
          margin: 0 auto;
        }
      }
    }

    .navigation__list {
      @include media-breakpoint-down(lg) {
        margin: 0;
        flex-direction: column;
        align-items: center;
        gap: 48px;
      }
    }

    .navigation__href {
      @include media-breakpoint-down(lg) {
        font-size: 32px;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    margin-top: 4px;
    gap: 32px;
  }

  &__item {
    display: inline-block;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;

    &:last-child {
      margin-right: 0;
    }
  }

  &.nav-footer {
    margin-top: -3px;
    @media (max-width: 991px) {
      display: flex;
      justify-content: center;
    }

    .navigation__href {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 130%;
      color: #FFFFFF;

      &:hover {
        @include media-up(1025) {
          color: $green;
        }
      }
    }
  }

  &__href {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
    transition: color .25s ease;

    &:hover {
      @include media-up(1025) {
        color: $green;
      }
      @include media-down(1024) {
        color: #FFFFFF;
      }
    }
  }
}

.navigation-mobile {
  display: none;
  @media (max-width: 991px) {
    display: block;
  }

  input + label {
    position: absolute;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 24px;
    z-index: 80;

    span {
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      margin-top: -1px;
      left: 0;
      display: block;
      background: var(--white);
      transition: .5s;
    }

    span:first-child {
      top: 3px;
    }

    span:last-child {
      top: 16px;
    }
  }

  label:hover {
    cursor: pointer;
  }

  input:checked + label {
    position: fixed;

    span {
      opacity: 0;
      top: 50%;
    }

    span:first-child {
      opacity: 1;
      transform: rotate(405deg);
    }

    span:last-child {
      opacity: 1;
      transform: rotate(-405deg);
    }
  }

  input ~ nav {
    background: rgba(0, 0, 0, 0.99);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 70;
    transition: .5s;
    transition-delay: .5s;
    overflow: hidden;

    > ul {
      text-align: center;
      position: absolute;
      top: 90px;
      left: 20%;
      right: 20%;

      > li {
        opacity: 0;
        transition: .5s;
        transition-delay: 0s;

        > a {
          text-decoration: none;
          text-transform: uppercase;
          color: var(--white);
          display: block;
          padding: 20px;
        }
      }
    }
  }

  input:checked ~ nav {
    height: 100%;
    width: 100%;
    transition-delay: 0s;

    > ul {
      > li {
        opacity: 1;
        transition-delay: .5s;
      }
    }
  }
}
