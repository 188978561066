.common-banner {
  padding: calc(var(--header-height) + 70px) 0 150px;
  background-color: var(--black);

  &__title {
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    color: var(--white);
    @include media-breakpoint-down(sm){
      font-size: 48px;
    }
  }

  &__block {
    max-width: 1130px;
    margin: 0 auto;
  }
}
