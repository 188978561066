.home-banner {
  height: 100vh;
  max-height: 840px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  background-size: cover;
  position: relative;
  background-color: $black;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 800px;
  }

  &__bg {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-up(lg) {
      top: 20px;
    }

    &__img {
      max-width: 375px;
      width: 100%;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: all 1s ease;
      @include media-breakpoint-down(lg) {
        display: none;
      }

      &.hide {
        opacity: 0;
      }
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    // &:before {
    //   content: '';
    //   height: 1px;
    //   width: 100%;
    //   position: absolute;
    //   left: 0;
    //   top: 70%;
    //   background: #ffffff;
    //   opacity: 0.2;
    //   z-index: 1;
    //   @include media-breakpoint-down(lg) {
    //     display: none;
    //   }
    // }
  }

  &__grid {
    display: flex;
    max-width: 1440px;
    width: 100%;
    height: 100vh;
    position: relative;
    transition: opacity 0.4s ease;
    @include media-up(1025) {
      max-height: 100%;
    }
  }

  &__item {
    height: 100%;

    &:nth-child(1) {
      flex: 0 0 34.93056%;
      width: 34.93056%;
      border-right: 1px solid rgba($white, 0.1);
    }

    &:nth-child(2) {
      flex: 0 0 27.36111%;
      width: 27.36111%;
      @include media-down(768) {
        flex: 0 0 40%;
        width: 40%;
      }
      @include media-down(414) {
        flex: 0 0 70%;
        width: 70%;
      }
    }

    &:nth-child(3) {
      flex: 0 0 37.63889%;
      width: 37.63889%;
      border-left: 1px solid rgba($white, 0.1);
    }

    &:nth-child(1),
    &:nth-child(3) {
      overflow: hidden;
      position: relative;
      z-index: 1;
      @include media-down(768) {
        flex: 0 0 30%;
        width: 30%;
      }
      @include media-down(414) {
        flex: 0 0 15%;
        width: 15%;
      }
    }

    video {
      position: absolute;
      object-fit: contain;
      width: 1920px;
      height: 100%;
      top: calc(var(--header-height) / 1.5);
      left: 48%;
      transform: translateX(-50%) scale(1.1);
      z-index: 0;
      @include media-breakpoint-down(xl) {
        transform: translateX(-50%) scale(1);
      }
      @include media-between(414, 632) {
        transform: translateX(-50%) scale(0.95);
      }
    }

    .canvas-video {
      display: block;
      height: 100% !important;
      position: absolute;
      z-index: 2;

      &.left {
        top: 20px;
        right: 0;
        transform: translateX(59%);
      }

      &.right {
        top: 60px;
        left: 0;
        transform: translateX(-63%);
        @include media-breakpoint-down(md) {
          top: 88px;
        }
        @include media-breakpoint-down(md) {
          top: 60px;
        }
      }
    }
  }

  &__title {
    font-family: var(--fontGilroyRegular);
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 40px;
      br {
        display: none;
      }
    }
    @media (min-width: 768px) {
      position: relative;
      top: 70px;
      font-size: 40px;
    }
    @media (min-width: 1280px) {
      font-size: 50px;
      width: 896px;
    }
    @media (min-width: 1440px) {
      font-size: 56px;
    }
  }
}
