.principles-wrapper {
  padding-top: 40px;
  @include media-breakpoint-down(lg) {
    padding-top: 64px;
  }
}

.principles {
  display: flex;
  background: var(--white);
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-bottom: 24px;
    color: var(--blue);
  }

  &__title {
    font-weight: 400;
    font-size: 32px;
    margin-bottom: 76px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
      br {
        display: none;
      }
    }
  }

  &__img {
    width: 464px;
    background: url('../img/upload/about-us-page/principles.png') center center / cover no-repeat;
    @include media-down(1100) {
      width: 350px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 250px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      background-position: center center;
      aspect-ratio: 1/1;
    }
  }

  &__textcol {
    display: flex;
    background: var(--white);
    width: calc(100% - 464px);
    padding: 80px 114px 60px 116px;
    flex-direction: column;
    @include media-breakpoint-down(xl) {
      padding: 60px 41px 40px 86px;
    }
    @include media-down(1100) {
      width: calc(100% - 350px);
      padding: 30px 56px 20px 56px;
    }
    @include media-breakpoint-down(lg) {
      width: calc(100% - 250px);
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 24px 24px 47px;
    }
  }
}

.principles-row {
  border-bottom: 1px solid var(--grey);
  padding: 40px 0;
  display: flex;
  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    padding: 20px 0;
  }

  &__number {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    display: inline-block;
    @include media-breakpoint-up(lg) {
      width: 45px;
    }
    @include media-breakpoint-down(lg) {
      margin-right: 8px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    width: 211px;
    min-width: 211px;
    @include media-breakpoint-up(xl) {
      margin-right: 15px;
    }
    @media (max-width: 767px) {
      width: 80%;
    }
  }

  &__paragrapgh {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    width: calc(100% - 256px);
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding-top: 30px;
    }
  }
}

.scroll-down {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 90%;
  right: 60px;
  background: var(--white);
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  @include media-breakpoint-down(lg) {
    display: none;
  }

  &:after {
    content: 'Scroll down';
    display: block;
    width: fit-content;
    position: absolute;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $white;
    white-space: nowrap;
  }

  &:hover {
    background-color: $green;

    .icon-check-green-small {
      transform: scale(1.3);
      stroke: $white;
    }
  }

  .icon-check-green-small {
    width: 12px;
    height: 6px;
    fill: none;
    stroke: $green;
    transition: all 0.3s ease;
  }
}

.hidden {
  display: none;
}
