.benefits {
  background: var(--black);
  padding: 60px 0 50px 0;
  @include media-breakpoint-down(md) {
    padding: 32px 0 41px 0;
  }

  .container {
    max-width: 1162px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    padding-bottom: 22px;
    color: $green;
  }

  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    color: var(--white);
    margin-bottom: 40px;
  }
}

.benefits-list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-inline: -16px;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  &__item {
    flex: 0 0 50%;
    width: 50%;
    padding: 16px;
    @include media-breakpoint-down(lg) {
      flex: 0 0 100%;
      width: 100%;

      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(4) {
        order: 4;
      }
    }
  }

  &__item-row {
    width: 100%;
    height: 100%;
    color: var(--white);
    background: var(--grey-items);
    display: flex;
    backdrop-filter: blur(10px);
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
      margin-inline: 0;
      &:not(:last-child) {
        margin-block: 0 24px;
      }
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }

    &:hover {
      @include media-breakpoint-up(lg) {
        .benefits-list__left-col,
        .benefits-list__right-col {
          background-color: var(--blue);
        }

        .icon,
        .benefits-list__item-text {
          opacity: 1;
          transform: translateY(0);
          top: 0;
        }
      }
    }
  }

  &__left-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 56px;
    width: calc(100% - 230px);
    height: 100%;
    padding: 32px 24px;
    transition: all 0.4s ease;
    @media (max-width: 379px) {
      width: 100%;
    }
    @include media-breakpoint-down(lg) {
      width: 70%;
      justify-content: flex-start;
      padding: 24px 16px;
      gap: 51px;
    }
    @include media-down(365) {
      width: 100%;
    }
  }

  &__right-col {
    transition: all 0.4s ease;
    width: 230px;
    @media (max-width: 379px) {
      width: 100%;
    }
    @media (min-width: 1400px) {
      width: 230px;
      height: 100%;
    }
    @include media-breakpoint-down(lg) {
      width: 30%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      max-height: 150px;
      overflow: hidden;
    }
    // @include media-down(365) {
    //   width: 100%;
    //   max-height: 200px;
    // }

    &__img {
      object-fit: cover;
      height: 100%;
      width: 100%;

      &.pc {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &.mobile {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }
  }

  &__title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: var(--white);
    @media (max-width: 767px) {
      font-size: 22px;
    }
    @media (max-width: 379px) {
      font-size: 20px;
    }
  }

  &__bottom {
    position: relative;
  }

  &__item-text {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $white;
    @include media-breakpoint-up(lg) {
      opacity: 0;
      transform: translateY(100px);
      transition: all 0.5s ease;
    }
  }

  .icon {
    margin-bottom: 15px;
    fill: none;
    stroke: $green;
    @include media-breakpoint-up(lg) {
      position: relative;
      transition: all 0.5s ease;
    }

    &-benefits1 {
      width: 56px;
      height: 83px;
      @include media-breakpoint-up(lg) {
        top: calc(100% - 83px);
      }
      @include media-breakpoint-down(lg) {
        width: 46px;
        height: 68px;
      }
    }

    &-benefits2 {
      width: 65px;
      height: 65px;
      @include media-breakpoint-up(lg) {
        top: calc(100% - 65px);
      }
      @include media-breakpoint-down(lg) {
        width: 46px;
        height: 46px;
      }
    }

    &-benefits3 {
      width: 56px;
      height: 56px;
      @include media-breakpoint-up(lg) {
        top: calc(100% - 56px);
      }
      @include media-breakpoint-down(lg) {
        width: 45px;
        height: 45px;
      }
    }

    &-benefits4 {
      width: 55px;
      height: 53px;
      @include media-breakpoint-up(lg) {
        top: calc(100% - 53px);
      }
      @include media-breakpoint-down(lg) {
        width: 44px;
        height: 42px;
      }
    }
  }
}
