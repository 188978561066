.footer {
  background: $black;
  color: $white;
  width: 100%;
  overflow: hidden;

  &__logo {
    margin-right: 64px;
    max-width: 241px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      margin: 0 auto 32px auto;
    }

    &-img {
      width: 100%;
    }
  }

  &__logo-clutch {
    max-width: 85px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      margin: 40px auto 0 auto;
    }

    &-img {
      width: 100%;
    }
  }

  &__colleft {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 100px);

    @media (max-width: 991px) {
      width: 100%;
      margin-top: 5px;
      align-items: normal;
      flex-direction: column;
      justify-content: center;
    }
    @media (min-width: 992px) {
      width: calc(100% - 300px);
    }
  }

  &__colright {
    max-width: 300px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 991px) {
      width: 100%;
      justify-content: center;
      max-width: 100%;
    }
  }

  &__bg {
    display: block;
    width: 325px;
    aspect-ratio: 1/1;
    background: url("../img/upload/bg-footer.png") 0 0 no-repeat;
    background-size: contain;
    position: absolute;
    left: 26.3%;
    top: 0;
    @include media-breakpoint-down(lg){
      display: none;
    }
  }

  &__header {
    width: 100%;
    padding: 40px 0 24px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: 991px) {
      flex-direction: column;
      padding: 40px 0 40px 0;
    }
  }

  &__texts {
    padding: 48px 0 40px 0;
    color: #FFFFFF;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.6;
    @media (max-width: 991px) {
      text-align: center;
      margin-bottom: 40px;

      br {
        display: none;
      }
    }
    @media screen and (min-width: 1440px) {
      max-width: 485px;
    }
  }

  &__address {
    margin-bottom: 0;
    text-align: right;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba($white, .6);

    a {
      @include inherit;
      transition: color .25s ease;

      &:hover {
        color: $green;
      }
    }

    @media (max-width: 991px) {
      text-align: center;
    }
  }
}

.footer-contact {
  font-family: "Gilroy", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 991px) {
    justify-content: center;
    margin-top: 20px;
  }

  &__item {
    margin-right: 20px;

    a {
      color: rgba($white, .6);
      transition: color .25s ease;

      &:hover {
        color: $green;
      }
    }

    &:after {
      content: '•';
      font-size: 14px;
      display: inline-block;
      padding-left: 18px;
      color: var(--green);
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }
  }
}

