.projects {
  padding: 40px 0 40px 0;
  background: var(--black);
  position: relative;

  .tabs__header {
    width: 100%;
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .tabs__header-item {
    width: fit-content;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    &:not(:last-child) {
      .projects__title {
        margin-right: 74px;
        @include media-breakpoint-down(xl) {
          margin-right: 42px;
        }
        @include media-down(370) {
          margin-right: 0;
        }

        &:after {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          position: absolute;
          top: 50%;
          right: -38px;
          transform: translateY(-50%);
          background-color: $white;
          border-radius: 50%;
          opacity: 0.5;
          @include media-breakpoint-down(md) {
            right: -28px;
          }
          @include media-down(370) {
            display: none;
          }
        }
      }
    }
  }

  &__allbtn {
    @extend .button;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    padding: 26px;
    font-weight: 400;
    font-size: 16px;
    color: var(--white);
    justify-content: center;
  }

  &__title {
    display: block;
    width: fit-content;
    padding-block: 10px;
    font-weight: 400;
    font-size: 32px;
    color: var(--white);
    position: relative;
    transition: all 180ms ease-in-out;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      font-size: 26px;
    }

    &:hover {
      @include media-up(1025) {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      transition: all 180ms ease-in-out;
      background: $white;
    }

    &.active {
      opacity: 1;

      &:before {
        width: 100%;
      }

      span {
        transform: scale(1.1);
        opacity: 1;
      }
    }

    span {
      display: block;
      @include inherit;
      opacity: 0.5;
      transition: all 0.3s ease;
    }
  }

  .row-gallery {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
    @include media-breakpoint-down(xl) {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .col-gallery {
    flex: 0 0 50%;
    width: 50%;
    padding-left: 32px;
    padding-right: 32px;
    @include media-breakpoint-down(xl) {
      padding-left: 16px;
      padding-right: 16px;
    }
    @media (max-width: 991px) {
      flex: 0 0 100%;
      width: 100%;
    }
  }
}

.tags {
  position: absolute;
  z-index: 15;
  top: 24px;
  left: 24px;
  min-height: 24px;
  background: var(--black);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 10px;
  @include media-breakpoint-down(md) {
    top: 13px;
    left: 13px;
  }

  &__item {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--white);
    position: relative;
    padding-right: 20px;

    &:after {
      content: '|';
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      opacity: 0.3;
      display: inline-block;
      margin-left: 9px;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }
}

.project {
  display: block;
  width: 100%;
  height: 769px;
  margin-bottom: 48px;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    height: inherit;
    aspect-ratio: 1/1.195;
    margin-bottom: 16px;
  }

  &:hover {
    @include media-up(1025) {
      &:before {
        background-color: rgba($black, 0);
      }

      .project__img {
        transform: scale(1.02);
      }
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba($black, 0.6);
    transition: all 0.6s ease;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: all 0.6s ease;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  &.type2 {
    @include media-breakpoint-up(md) {
      height: 619px;
    }
  }

  &.mt-negative {
    @include media-breakpoint-up(lg) {
      margin-top: -150px;
    }
  }

  &__info {
    width: 100%;
    padding-left: 24px;
    position: absolute;
    bottom: 0;
    color: var(--white);
    left: 0;
    z-index: 15;
    @include media-breakpoint-down(md) {
      padding-left: 13px;
    }
  }

  &__name {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    color: var(--white);
    margin-bottom: 23px;
    max-width: 350px;
    position: relative;
    z-index: 15;
    @include media-breakpoint-down(md) {
      margin-bottom: 14px;
      font-size: 24px;
    }
  }

  &__description {
    max-width: 335px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1;
    color: var(--white);
    margin-bottom: 36px;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  &__card-link {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: -24px;
    padding: 26px 24px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: $white;
    border-top: 1px solid rgba($white, 0.1);
    transition: all 0.3s ease;

    &:hover {
      @include media-up(1025) {
        .icon {
          stroke: $green;
          transform: translateX(5px);
        }
        span {
          color: $green;
        }
      }
      @include media-down(1025) {
        span {
          color: $white;
        }
      }
    }

    span {
      @include inherit;
      transition: color 0.2s ease;
    }

    .icon {
      width: 10px;
      height: 15px;
      fill: none;
      stroke: $white;
      transition: all 0.3s ease;
    }
  }
}
