.cookie-policy-page {
  .common-cover-section__content {
    table {
      th,
      td {
        &:nth-child(1) {
          width: 23.11111%;
        }

        &:nth-child(2) {
          width: 44.88889%;
        }

        &:nth-child(3) {
          width: 31.77778%;
        }
      }
    }
  }
}

.home-page {
  .header {
    background-color: $black;
  }
}

.project-body {
  padding-top: var(--header-height);
  background-color: $white;
  @include media-breakpoint-down(lg) {
    padding-top: 53px;
  }

  &.transparent-header {
    padding-top: 0;

    .header {
      &.bg-black-static {
        background-color: transparent;
      }

      &.bg-black {
        background-color: var(--black);
      }

      .btn-primary {
        border: 1px solid $white;

        span {
          color: $white;
        }

        &:hover {
          @include media-up(1025) {
            background-color: $green !important;
            border-color: $green !important;
          }
        }

        &:before,
        &:after {
          display: none;
        }
      }
    }
  }

  &:not(.transparent-header) {
    .header {
      .btn-primary {
        border: none;
      }
    }
  }

  .tags {
    position: static;
    background: none;

    &__item {
      padding: 0.3rem 1rem;

      &:after {
        display: none;
      }
    }
  }

  .project-page-reverse-block__wrapper {
    max-width: 1170px !important;
    width: 100% !important;
  }

  .page-header--project-banking {
    height: var(--header-height);
    background-color: var(--blue-dark);
  }

  .page-header--project-common-black {
    height: var(--header-height);
    background-color: var(--black);
  }

  .page-header--project-harker {
    min-height: 17rem;
    background-image: url(../photos/harker/header-banner.png);
    background-size: cover;
    background-position: center 100%;
  }

  .on-game-score-block {
    max-width: 833px !important;
    width: 100% !important;
    display: flex !important;
    gap: 33px !important;
    @include media-breakpoint-down(lg) {
      max-width: 402px !important;
      flex-direction: column;
    }
  }

  .on-game-score-block__logos {
    width: 100%;
    margin: 0 !important;
  }

  .on-game-score-block__table {
    width: 100%;
    border-collapse: unset !important;

    &.scaleIn {
      .on-game-score-block__table-row {
        transform: translateY(0);

        .on-game-score-block__table-cell--united,
        .on-game-score-block__table-cell--logo {
          transform: scale(1);
        }
      }
    }
  }

  .on-game-score-block__table-row {
    transform: translateY(-50%);
    transition: all 0.5s ease-out;
    @for $i from 2 through 6 {
      &:nth-child(#{$i}) {
        &,
        .on-game-score-block__table-cell--united,
        .on-game-score-block__table-cell--logo {
          transition-delay: #{$i}00ms;
        }
      }
    }
  }

  .on-game-score-block__table-cell--united,
  .on-game-score-block__table-cell--logo {
    width: 55px !important;
    height: 40px !important;
    transform: scale(0);
    transition: all 0.5s ease-out;
  }

  .page-header--project-university {
    min-height: 17rem;
    background-image: url(../photos/university/header-banner.png);
    background-size: cover;
    background-position: center 100%;
  }

  .page-header--project-trawell {
    min-height: 17rem;
    background-image: url(../photos/booking/trawell-top-bg.jpg);
    background-size: cover;
    background-position: center;
    @include media-breakpoint-down(md) {
      min-height: 22rem;
    }
  }

  .page-header--project-on-game {
    min-height: 17rem;
    background-image: url(../photos/on-game/bg-1.jpg);
    background-size: cover;
    background-position: center;
  }

  .page-header--project-yapper {
    min-height: 17rem;
    background-image: url(../photos/yapper/bg-top.jpg);
    background-size: cover;
    background-position: center 100%;
  }

  .page-header--project-gambling {
    min-height: 17rem;
    background-image: url(../photos/gambling/bg-top.jpg);
    background-size: cover;
    background-position: center 100%;
  }

  .page-header--project-yow {
    min-height: 17rem;
    background-image: url(../photos/yow/yow-top-background.png);
    background-size: 150%;
    background-position: top right 40%;
    @media (min-width: 1200px) {
      background-position: left top -60px;
    }
    @media (min-width: 768px) {
      min-height: 22rem;
      background-size: 100%;
      background-position: top;
    }
  }

  .yow-logo__icon-wrapper {
    @for $i from 2 through 5 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i}00ms;
      }
    }
  }
}
