.services-card {
  background-color: $grey-100;

  &:hover {
    @include media-up(1025) {
      .services-card__col {
        &:before {
          opacity: 0;
        }

        &__img {
          transform: translate(-50%, -50%) scale(1.02);
        }
      }
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__col {
    width: 100%;
    min-height: 480px;
    max-height: 480px;
    @include media-breakpoint-down(md) {
      width: 100%;
      min-height: initial;
      max-height: initial;
    }

    &:first-child {
      max-width: 552px;
      overflow: hidden;
      position: relative;
      @include media-breakpoint-down(md) {
        max-width: initial;
        min-height: 260px;
        max-height: 260px;
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.4);
        transition: opacity 0.5s ease;
      }
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 64px 88px 40px;
      @include media-breakpoint-down(xl) {
        padding: 40px 40px 20px;
      }
      @include media-breakpoint-down(lg) {
        padding: 35px 24px 14px;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;
    }
  }

  &__body {
    padding-bottom: 31px;
  }

  &__title {
    margin-bottom: 32px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    color: $white;
    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;
    }
    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }

  &__text {
    margin-bottom: 32px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: $white;
    max-height: 100px;
    min-height: 100px;
    overflow-y: auto;
    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;
      font-size: 16px;
    }
    @include media-breakpoint-down(md) {
      max-height: 150px;
      min-height: 150px;
    }
  }

  &__subtitle {
    margin-bottom: 6px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $white;
    opacity: 0.5;
  }

  &__list {
    &-item {
      display: flex;
      align-items: center;
      gap: 12px;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      color: $white;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }

      &:not(:last-child) {
        margin-bottom: 9px;
      }

      .icon-done {
        width: 18px;
        height: 12px;
        fill: none;
        stroke: $green;
      }

      span {
        @include inherit;
      }
    }
  }

  &__footer {
    border-top: 1px solid rgba($white, 0.1);
  }
}
