.single-services-banner {
  position: relative;
  padding-top: var(--header-height);

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    padding: 271px 0 271px;
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(lg) {
      padding: 249px 0 212px;
    }
  }

  &__title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.03em;
    color: $white;
  }

  &__subtitle {
    padding: 5px 10px;
    width: fit-content;
    margin-inline: auto;
    margin-bottom: 16px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $black-secondary;
    background: $white;
    border-radius: 8px;
  }
}
