.contact-us {
  .container {
    max-width: 1190px;
  }
}

.page-header {
  padding-top: var(--header-height);
  max-height: 352px;
  min-height: 352px;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--black);
  @include media-breakpoint-down(lg) {
    padding-top: var(--header-height);
    max-height: 291px;
    min-height: 291px;
  }

  &__title {
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    color: var(--white);
    margin-top: -73px;
    @media (max-width: 767px) {
      font-size: 42px;
    }
    @media (max-width: 991px) {
      font-size: 48px;
    }

  }
}

.page-container {
  width: 100%;
  margin: 0 auto;
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    width: 1132px;
  }
  @media (max-width: 991px) {
    padding: 0 15px;
  }
}

.page-content {
  width: 100%;
  margin: -73px auto 0 auto;
  min-height: 600px;
}

.contactus {
  max-width: 1160px;
  width: calc(100% - 30px);
  margin: -50px auto 0 auto;
  @include media-breakpoint-down(lg) {
    margin: -84px auto 30px auto;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }

  &__box {
    padding: 66px 116px 72px;
    width: calc(100% + 30px);
    margin-inline: -15px;
    margin-bottom: 43px;
    background: var(--white);
    border-top: 6px solid var(--green);
    @include media-breakpoint-down(lg) {
      padding: 32px 24px 30px;
      margin-bottom: 46px;
    }

    .row {
      width: calc(100% + 30px);
    }
  }

  &__form {
    width: 100%;
    @media (min-width: 1440px) {
      max-width: 434px;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 120%;
    color: var(--main-text-color);
    margin-bottom: 32px;
    @media (max-width: 991px) {
      font-size: 30px;
      text-align: center;
      margin-bottom: 24px;
    }
  }

  &__text {
    @media (min-width: 992px) {
      padding-right: 25px;
    }
    @media (min-width: 1280px) {
      max-width: 320px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: var(--main-text-color);

    @media (max-width: 991px) {
      font-size: 21px;
      padding-bottom: 42px;
      text-align: center;
    }
  }
}

.contact {
  margin-bottom: 65px;

  &__icon {
    display: inline-block;
    width: 16px;
    height: 16px;

    &.tel {
      background: url('../svg/Phone.svg') 0 0 no-repeat;
      background-size: contain;
    }

    &.location {
      background: url('../svg/Location.svg') 0 0 no-repeat;
      background-size: contain;
    }

    &.email {
      background: url('../svg/Email.svg') 0 0 no-repeat;
      background-size: contain;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 22px auto;
    gap: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 16px;

    a {
      display: block;
      width: fit-content;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--white);
      transition: color .25s ease;

      &[href^='mailto'] {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          position: absolute;
          top: calc(100% + 1px);
          left: 0;
          background-color: $white;
          transition: background-color .25s ease;
        }
      }

      &:hover {
        @include media-up(1025) {
          color: $green;

          &[href^='mailto'] {
            &:after {
              background-color: $green;
            }
          }
        }
      }
    }
  }
}

.contact-details {
  background: var(--black);
  margin-top: 42px;
  color: var(--white);
  width: calc(100% + 30px);
  margin-inline: -15px;
  margin-bottom: 75px;
  @media (max-width: 991px) {
    flex-direction: column;
    width: calc(100% + 30px);
    margin-inline: -15px;
    margin-bottom: 30px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }

  .social-list {
    gap: 0;
  }

  .social-list__item {
    width: fit-content;
    height: fit-content;

    .social-list__href {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      transition: all .34s ease;

      &:hover {
        @include media-up(1025) {
          background-color: $white;

          .icon {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: var(--white);
  }

  &__box {
    background: var(--black);
    max-width: 320px;
    padding: 72px 24px 72px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  &__map {
    position: relative;
    background: var(--black);
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(lg) {
      height: 440px;
    }

    .leaflet-control-attribution {
      display: none;
    }

    .leaflet-top.leaflet-left {
      top: initial;
      left: initial;
      right: 6px;
      bottom: 6px;
    }

    .leaflet-control-zoom.leaflet-bar.leaflet-control {
      margin: 0;
    }

    .leaflet-bar {
      border: none;
      background-clip: initial;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
      border-radius: 6px;
      overflow: hidden;
    }

    .leaflet-bar a {
      font-family: "Gilroy", sans-serif;
      background-color: $white;
      border-radius: 0 0 0 0 !important;
      transition: color .2s ease;
      font-weight: 900;
      color: rgba($black, .6);
      border: none;

      &:first-child {
        position: relative;

        &:after {
          content: '';
          display: block;
          width: calc(100% - 8px);
          height: 1px;
          position: absolute;
          left: 50%;
          top: calc(100% - 1px);
          transform: translateX(-50%);
          background-color: rgba($black, .1);
        }
      }

      &:hover {
        @include media-up(1025) {
          color: $green;
        }
      }
    }

    iframe {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }

}

//forms inputs
.form-group {
  position: relative;
  margin-bottom: 23px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
  }

  .just-validate-error-label {
    position: absolute;
    padding-top: 3px;
    left: 0;
    top: 100%;
    font-size: 12px;
    color: var(--red);
  }
}

textarea.form-control {
  height: 107px;
  resize: none;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 49%;
  z-index: 1;
  width: 0;
  height: 1px;
  background-color: var(--green);
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transform: translateX(-50%);
}

.form-control {
  display: inline-block;
  width: 100%;
  color: var(--main-text-color);
  border: none;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 32px 16px 120px;
  font-family: var(--fontGilroyRegular);
  border-bottom: 1px solid var(--form-gray);
  position: relative;
  @include media-breakpoint-down(lg) {
    padding: 16px 32px 16px 0;
  }

  &.is-invalid ~ .underline {
    background-color: var(--red);
    width: 100%;
  }

  &.is-valid ~ .underline {
    background-color: var(--green);
    width: 100%;
  }

  &.is-valid {
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url('data:image/svg+xml,%3Csvg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1 5L5 9L13.25 0.75" stroke="%2377C663" stroke-width="1.5"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
  }

  &:focus + .underline {
    width: 100%;
  }
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin-top: 27px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &.scaleClass {
    @include media-breakpoint-up(md) {
      height: 51px;
    }

    .form-captcha {
      height: 51px;
      @include media-breakpoint-down(md) {
        margin-left: 4px;
      }

      iframe {
        height: 76px;
        width: 303px;
        transform: scale(0.66) translate(-29%, -29%);
      }
    }

    .btn-primary {
      @include media-breakpoint-up(md) {
        margin-left: -101px;
      }
    }
  }

  .btn-primary {
    width: 188px;
    min-width: 188px;
    height: 51px;
    padding: 16px 68px;
  }
}

.form-label {
  font-size: 16px;
  font-family: var(--fontGilroyRegular);
  color: $black;
  opacity: .4;
  max-width: 110px;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 2;
  @include media-breakpoint-down(lg) {
    top: -7px;
  }
}

.contactus {
  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;

    font: inherit;
    width: 22px;
    height: 22px;
    border: 1px solid var(--grey-label);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    transition: all .3s ease;


    &:disabled {
      opacity: 0.4;
    }

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      transform: scale(0);
      transition-delay: 100ms;
      background: url('../svg/checked.svg') 0 0 no-repeat;
      background-size: contain;
      position: relative;
      top: 1px;
      left: 1px;
    }

    &:checked {
      background: var(--green);
      border: 1px solid var(--green);

      &:before {
        transform: scale(1);
      }
    }
  }
}

.question-section {
  @extend .contactus;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 72px;

  &:before {
    display: none;
  }

  border-top: none;
}

.terms {
  text-decoration: underline;
  color: var(--main-text-color);
  display: inline-block;
  transition: all .3s ease;

  &:hover {
    @include media-up(1025) {
      color: $green;
      text-decoration: underline;
    }
  }
}

.form-checkbox {
  &-label {
    display: grid;
    grid-template-columns: 22px auto;
    gap: 16px;
    align-items: center;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
}
